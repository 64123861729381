<form class="form-horizontal" [formGroup]="userCreateForm" (ngSubmit)="createUser()" data-bitwarden-watching="1">
    <div class="card-body">
        <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label">Username</label>
            <div class="col-sm-10">
                <input type="email" class="form-control" id="username" name="username"
                formControlName="username" placeholder="johndoe">
            </div>
        </div>
        <div class="form-group row">
            <label for="inputPassword3" class="col-sm-2 col-form-label">Password</label>
            <div class="col-sm-10">
                <input type="password" class="form-control" id="password" name="password"
                formControlName="password" placeholder="password">
            </div>
        </div>
        <div class="form-group row">
            <label for="inputPassword3" class="col-sm-2 col-form-label">Confirm password</label>
            <div class="col-sm-10">
                <input type="password" class="form-control" id="retypepassword" name="retypepassword"
                formControlName="retypepassword" placeholder="password">
            </div>
        </div>
        <div class="form-group row">
            <label for="inputPassword3" class="col-sm-2 col-form-label">Admin user</label>
            <div class="col">
                <input type="checkbox" class="form-control" id="admin" name="admin"
                formControlName="admin">
            </div>
        </div>
    </div>
    <div class="card-footer">
        <button type="submit" class="btn btn-info float-right"
        [disabled]="!userCreateForm.valid" >
            <i class="far fa-solid fa-user-plus nav-icon"></i>&#160; &#160;Create user</button>
    </div>
    <!--Loading mask-->
    <div class="overlay" *ngIf="loadingMaskShow">
        <div class="spinner-border text-info" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Busy...</span>
        </div>
    </div>
    <!--Loading mask end-->
</form>