<!--Top-->
<div class="row">
    <div class="col d-flex justify-content-center">
        <div class="card card-outline card-primary bg-light">
            <!--Card body-->
            <div class="card-body">
                <form [formGroup]="domainCreateForm" (ngSubmit)="createDomain()">
                    <div class="row">
                        <div class="col">
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" id="hostdomain" name="hostdomain"
                                    formControlName="hostdomain" placeholder="example.com">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">on</span>
                                </div>
                                <input type="text" class="form-control" id="ipAddr" name="ipAddr"
                                    formControlName="ipAddr" placeholder="192.168.1.1">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!--Subdomain create-->
                        <div class="col">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">https://</span>
                                </div>
                                <input type="text" class="form-control" id="subdomain" name="subdomain"
                                    formControlName="subdomain">
                                <div class="input-group-append">
                                    <span class="input-group-text">.nohostfileneeded.com</span>
                                    <button type="submit" class="btn btn-info"
                                        [disabled]="!domainCreateForm.valid">Create</button>
                                </div>
                            </div>
                        </div>
                        <!--Subdomain creation end-->
                    </div>
                </form>
            </div>
            <!--Card body end-->
            <!--Loading mask-->
            <div class="overlay" *ngIf="loadingMaskShow">
                <div class="spinner-border text-info" style="width: 3rem; height: 3rem;" role="status">
                    <span class="sr-only">Busy...</span>
                </div>
            </div>
            <!--Loading mask end-->
        </div>
    </div>
</div>
<!--Top end-->


<!-- List -->
<div class="row">
    <div class="container-fluid">
        <div class="card  card-outline card-dark">
            <!--Card body-->
            <div class="card-body">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th style="width: 10px">#</th>
                            <th>Subdomain</th>
                            <th>Live Domain</th>
                            <th>IP</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let domain of allDoamins;index as i">
                            <td>{{i+1}}.</td>
                            <td>
                                <a class="link text-primary" target="_blank" href="https://{{domain.subdomain}}">https://{{domain.subdomain}}</a>
                                <br />
                                <a class="link text-secondary" target="_blank" href="http://{{domain.subdomain}}">http://{{domain.subdomain}}</a>
                            </td>
                            <td>{{domain.hostdomain}}</td>
                            <td>{{domain.ipaddr}}</td>
                            <td>
                                <button type="button" class="btn btn-light btn-outline-dark"
                                    (click)="generateQR(domain.subdomain)">
                                    <i class="far fa-solid fa-qrcode"></i>&#160; QR & URL
                                </button>
                                &#160;
                                <button type="button" class="btn btn-light btn-outline-danger"
                                    (click)="showDeletionPromptModal(domain.recordid,domain.subdomain,domain.hostdomain)">
                                    <i class="far fa-solid fa-trash-can"></i>&#160; Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<!-- List end -->


<!--QR display modal-->
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':modalDomainCreationDisplayStyle}"
    id="modalDomainCreation">
    <div class="modal-dialog">
        <div class="modal-content bg-dark">
            <div class="modal-header">
                <h4 class="modal-title"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #modalDomainCreationCloseBtn
                    (click)="closeQRModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    <qr-code value="{{modalDomainCreationFinalURL}}" size="470" errorCorrectionLevel="M"></qr-code>
                </p>

                <div class="input-group mb-3">
                    <select class="form-control"
                        (change)="modalDomainCreationFinalURLType='https'; httpHttpsChanged($event)">
                        <option value="http">HTTP</option>
                        <option value="https" selected>HTTPS</option>
                    </select>
                </div>

                <p>
                    <a class="h4 link-dark" target="_blank"
                        href="{{modalDomainCreationFinalURL}}">{{modalDomainCreationFinalURL}}</a>
                </p>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-success" data-dismiss="modal"
                    (click)="closeQRModal()">Close</button>
            </div>
        </div>
    </div>
</div>
<!--QR display modal end-->



<!--Domain deletion prompt modal-->
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':modalDomainDeletionPromptDisplayStyle}"
    id="modalDomainDeletion">
    <div class="modal-dialog">
        <div class="modal-content bg-warning">
            <div class="modal-header">
                <h4 class="modal-title">Are you sure ?</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #modalDomainDeletionCloseBtn
                    (click)="closeDeletionPromptModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    Are you sure you want to delete the domain <br />
                    <strong>{{modalDomainDeletionPromptHostDomain}}</strong><br />
                    hosted over <br />
                    <strong>{{modalDomainDeletionPromptSubDomain}}</strong> ?
                </p>
            </div>
            <div class="modal-footer justify-content-right">
                <button type="button" class="btn btn-danger" data-dismiss="modal"
                    (click)="deleteDomain(modalDomainDeletionPromptDomainId)"><i
                        class="far fa-solid fa-trash-can"></i>&#160;Delete</button>
                <button type="button" class="btn btn-info" data-dismiss="modal" (click)="closeDeletionPromptModal()"><i
                        class="far fa-solid fa-circle-xmark"></i>&#160;Close</button>
            </div>
        </div>
    </div>
</div>
<!--Domain deletion prompt modal end-->