import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { customHttpInterceptor } from './custom-http.interceptor';
import { DomainsComponent } from './domains/domains.component';
import { UserlistComponent } from './userlist/userlist.component';
import { UsercreateComponent } from './usercreate/usercreate.component';
import { ProfileComponent } from './profile/profile.component';
import { ToastrModule } from 'ngx-toastr';
import { QrCodeModule } from 'ng-qrcode';


export const globalEnvironment = {
  authTokenKey : 'authToken',
  //apiHostURL : 'http://172.168.16.75/api', // local
  apiHostURL : 'https://nohostfileneeded.com/api', // prod
  //apiHostURL : '/api', //live
};


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LayoutComponent,
    DomainsComponent,
    UserlistComponent,
    UsercreateComponent,
    ProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    QrCodeModule
  ],
  providers: [
    provideClientHydration(),
    provideHttpClient(withInterceptors([customHttpInterceptor])
    ),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
