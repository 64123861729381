<form class="form-horizontal" [formGroup]="profileUpdateForm" (ngSubmit)="updatePassword()" data-bitwarden-watching="1">
    <div class="card-body">
        <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label">Current password</label>
            <div class="col-sm-10">
                <input type="password" class="form-control" id="currentpassword" name="currentpassword"
                formControlName="currentpassword" placeholder="password">
            </div>
        </div>
        <div class="form-group row">
            <label for="inputPassword3" class="col-sm-2 col-form-label">New password</label>
            <div class="col-sm-10">
                <input type="password" class="form-control" id="newpassword" name="newpassword"
                formControlName="newpassword" placeholder="password">
            </div>
        </div>
        <div class="form-group row">
            <label for="inputPassword3" class="col-sm-2 col-form-label">Confirm new password</label>
            <div class="col-sm-10">
                <input type="password" class="form-control" id="retypepassword" name="retypepassword"
                formControlName="retypepassword" placeholder="password">
            </div>
        </div>
    </div>
    <div class="card-footer">
        <button type="submit" class="btn btn-info float-right"
        [disabled]="!profileUpdateForm.valid" >Update</button>
    </div>
    <!--Loading mask-->
    <div class="overlay" *ngIf="loadingMaskShow">
        <div class="spinner-border text-info" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Busy...</span>
        </div>
    </div>
    <!--Loading mask end-->
</form>