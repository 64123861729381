import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { NotifyService } from '../notify.service';
import { globalEnvironment } from '../app.module';

@Component({
  selector: 'app-usercreate',
  templateUrl: './usercreate.component.html',
  styleUrl: './usercreate.component.scss'
})
export class UsercreateComponent implements OnInit{
  errorMessage: string | null | undefined = null;
  userCreateForm!: FormGroup;
  reTryCount: number = 0;
  loadingMaskShow: boolean = false;

  subscription !: Subscription;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private notifyService : NotifyService
  ) { }


  ngOnInit(): void {
    // Create form
    this.userCreateForm = new FormGroup({
      username: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(4)
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
      ])),
      retypepassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8)
      ])),
      admin: new FormControl(false, Validators.compose([
        Validators.required,
      ]))
    });
  }


  /**Clear form */
  clearForm(){
    this.userCreateForm.patchValue({
      username:"",
      password:"",
      retypepassword: "",
      admin: false
    });
  }


  /**Create user */
  createUser(){
    if (this.userCreateForm.valid) {
      let _jsonData = {
        "username":this.userCreateForm.value.username,
        "password":this.userCreateForm.value.password,
        "retypepassword":this.userCreateForm.value.retypepassword,
        "admin":this.userCreateForm.value.admin,
      };
      console.log(_jsonData);
      //show mask
      this.loadingMaskShow = true;
      this.http.put(globalEnvironment.apiHostURL+"/user",_jsonData).subscribe(
        (response:any) => {
          //hide mask
          this.loadingMaskShow = false;
          this.notifyService.showSuccess(response.message,"User created");
          //clear
          this.clearForm();
          //navigate to list users
          this.router.navigate(['/users']);
        },
        (error) => {
          //hide mask
          this.loadingMaskShow = false;
          //on auth invalid
          console.log("Return status = "+error);
          if(error.status==401){
            this.authService.removeLoginToken();
            this.router.navigate(['/login']);
          }
          //notify
          this.notifyService.showError(error.error.message,"User creation failed");
        });
    } else {
      // State as an error
      this.errorMessage = "Please tr-try with correct information";
    }
  }

}
