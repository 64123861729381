import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { globalEnvironment } from '../app.module';
import { Subscription, timer } from 'rxjs';
import { NotifyService } from '../notify.service';

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrl: './domains.component.scss'
})
export class DomainsComponent implements OnInit {
  errorMessage: string | null | undefined = null;
  domainCreateForm!: FormGroup;
  reTryCount: number = 0;
  loadingMaskShow: boolean = false;
  generatedSubDomain = "";

  modalDomainCreationDisplayStyle = "none";
  modalDomainCreationFinalURLType = "https";
  modalDomainCreationURL = "";
  modalDomainCreationFinalURL = "";

  modalDomainDeletionPromptDisplayStyle = "none";
  modalDomainDeletionPromptDomainId = 0;
  modalDomainDeletionPromptSubDomain = "";
  modalDomainDeletionPromptHostDomain = "";

  allDoamins = [{ recordid: 0, subdomain: "", enabled: false, hostdomain: "", ipaddr: "", expireat: "" }];

  subscription !: Subscription;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private notifyService: NotifyService
  ) { }


  ngOnInit(): void {
    // Create form
    this.domainCreateForm = new FormGroup({
      ipAddr: new FormControl('', Validators.compose([
        Validators.required
      ])),
      subdomain: new FormControl('', Validators.compose([
        Validators.required
      ])),
      hostdomain: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });
  }

  /**
   * Generate on view init
   */
  ngAfterViewInit(): void {
    // Random domain generate
    this.generateNextDomain();
    this.getAllDomains();
  }


  /**
   * Comain creation type changes
   * @param value either "ip" or "dir"
   */
  generateNextDomain() {
    this.http.get(globalEnvironment.apiHostURL + "/domain/generaterandom?" + Math.random()).subscribe(
      (response: any) => {
        this.generatedSubDomain = response.randomdomain;
        this.domainCreateForm.patchValue({
          subdomain: this.generatedSubDomain,
          ipAddr: "",
          hostdomain: ""
        });
        //get all Domains too
        this.getAllDomains();
      },
      (error) => {
        //on auth invalid
        console.log("Return status = " + error);
        if (error.status == 401) {
          this.authService.removeLoginToken();
          this.router.navigate(['/login']);
        }
      });
  }


  /**
   * Get all domains
   */
  getAllDomains(): void {
    this.http.get(globalEnvironment.apiHostURL + "/domain?" + Math.random()).subscribe(
      (response: any) => {
        this.allDoamins = response.domains;
      },
      (error) => {
        //on auth invalid
        console.log("Return status = " + error);
        if (error.status == 401) {
          this.authService.removeLoginToken();
          this.router.navigate(['/login']);
        }
      });
  }


  /**
   * Close QR modal
   */
  closeQRModal(): void {
    this.modalDomainCreationDisplayStyle = "none";
    this.modalDomainCreationFinalURL = "";
  }

  /**
   * Show QR modal
   */
  showQRModal(): void {
    this.modalDomainCreationDisplayStyle = "block";
  }

  /**HTTP or HTTPS changed */
  httpHttpsChanged(value: any): void {
    this.modalDomainCreationFinalURLType = value.target.value;
    this.generateQR(this.modalDomainCreationURL);
  }

  /**
   * Generate QR
   */
  generateQR(subdomain: string): void {
    this.modalDomainCreationURL = subdomain;
    this.modalDomainCreationFinalURL = this.modalDomainCreationFinalURLType + "://" + this.modalDomainCreationURL;
    this.showQRModal();
  }


  /**
   * Create domain
   */
  createDomain(): void {
    if (this.domainCreateForm.valid) {
      let _jsonData = {
        "ipaddr": this.domainCreateForm.value.ipAddr,
        "hostdomain": this.domainCreateForm.value.hostdomain,
        "subdomain": this.domainCreateForm.value.subdomain
      };
      //show mask
      this.loadingMaskShow = true;
      this.http.put(globalEnvironment.apiHostURL + "/domain", _jsonData).subscribe(
        (response: any) => {
          //hide mask
          this.loadingMaskShow = false;
          this.notifyService.showSuccess(response.message, "Domain created");
          //generate next
          this.generateNextDomain();
          //show
          this.modalDomainCreationFinalURLType='https';
          this.generateQR(response.message);
        },
        (error) => {
          //hide mask
          this.loadingMaskShow = false;
          //on auth invalid
          console.log("Return status = " + error);
          if (error.status == 401) {
            this.authService.removeLoginToken();
            this.router.navigate(['/login']);
          }
          //notify
          this.notifyService.showError(error.error.message, "Domain creation failed");
          this.generateNextDomain();
        });
    } else {
      // State as an error
      this.errorMessage = "Please tr-try with correct information";
    }
  }



  /**
 * Show Deletion prompt modal
 */
  showDeletionPromptModal(domainId:number, subDomain:string,hostDomain:string): void {
    this.modalDomainDeletionPromptDomainId = domainId;
    this.modalDomainDeletionPromptSubDomain = subDomain;
    this.modalDomainDeletionPromptHostDomain = hostDomain;
    this.modalDomainDeletionPromptDisplayStyle = "block";
  }

  /**
  * Close Deletion promtp modal
  */
  closeDeletionPromptModal(): void {
    this.modalDomainDeletionPromptDisplayStyle = "none";
    this.modalDomainDeletionPromptDomainId = 0;
    this.modalDomainDeletionPromptSubDomain = "";
    this.modalDomainDeletionPromptHostDomain = "";
  }

  /**
   * Delete domain
   */
  deleteDomain(domainId: any): void {
    this.http.delete(globalEnvironment.apiHostURL + "/domain/" + domainId).subscribe(
      (response: any) => {
        this.closeDeletionPromptModal();
        //hide mask
        this.loadingMaskShow = false;
        this.notifyService.showSuccess(response.message, "Domain deleted");
        this.generateNextDomain();
      },
      (error) => {
        //hide mask
        this.loadingMaskShow = false;
        //on auth invalid
        console.log("Return status = " + error);
        if (error.status == 401) {
          this.authService.removeLoginToken();
          this.router.navigate(['/login']);
        }
        this.closeDeletionPromptModal();
        //notify
        this.notifyService.showError(error.error.message, "Domain deletion failed");
        this.generateNextDomain();
      });
  }

}
