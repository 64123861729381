import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { DomainsComponent } from './domains/domains.component';
import { UserlistComponent } from './userlist/userlist.component';
import { UsercreateComponent } from './usercreate/usercreate.component';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
  {
    path:'login',
    component: LoginComponent
  },
  {
    path:'',
    redirectTo:'login',
    pathMatch: 'full'
  },
  {
    path:'',
    component: LayoutComponent,
    children:[
      {
        path:'domains',
        component: DomainsComponent,
      },
      {
        path:'users',
        component: UserlistComponent,
      },
      {
        path:'users/create',
        component: UsercreateComponent,
      },
      {
        path:'profile',
        component: ProfileComponent,
      },
      {
        path:'',
        component: LayoutComponent,
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
