import { Inject, Injectable } from '@angular/core';
import { globalEnvironment } from './app.module';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  //login error
  loginError: string = "";

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document
  ) { }

  /**
   * Set token in local storage
   * 
   * @param token Login token
   */
  setLoginToken(token: string) {
    if (document.defaultView?.localStorage != undefined) {
      const localStorage = document.defaultView?.localStorage;
      localStorage.setItem(globalEnvironment.authTokenKey, token);
    }
  }

  /**
   * Get token from the local storage and return if set
   */
  getLoginToken(): string  | null | undefined {
    let loginToken: string | null  = null;
    if (document.defaultView?.localStorage != undefined) {
      const localStorage = document.defaultView?.localStorage;
      let _tmpToken = localStorage.getItem(globalEnvironment.authTokenKey);
      if (_tmpToken != null) {
        loginToken = _tmpToken;
      }
    }
    //return
    return loginToken;
  }

  /**
   * Remove login token
   */
  removeLoginToken() {
    if (document.defaultView?.localStorage != undefined) {
      const localStorage = document.defaultView?.localStorage;
      localStorage.removeItem(globalEnvironment.authTokenKey);
    }
  }

  /**
   * Perform a login
   * 
   * @param username Username
   * @param password Password
   * @returns 
   */
  login(username: string, password: string): boolean {
    let returnStatus: boolean = false;
    // Process login
    var _jsonData = JSON.stringify({
      "username": username,
      "password": password
    });
    // call login API
    this.http.post(globalEnvironment.apiHostURL + "/user/login", _jsonData).subscribe(
      (response: any) => {
        this.setLoginToken(response.authtoken);
        this.loginError = "";
        returnStatus = true;
      },
      (error) => {
        this.loginError = error.error.message;
        this.removeLoginToken();
      }
    );
    //return
    return returnStatus;
  }

  /**
   * 
   * @returns Get if any error
   */
  getLoginError(): string | undefined{
    return this.loginError;
  }
}