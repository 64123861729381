import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { NotifyService } from '../notify.service';
import { Router } from '@angular/router';
import { globalEnvironment } from '../app.module';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
  errorMessage: string | null | undefined = null;
  profileUpdateForm!: FormGroup;
  reTryCount: number = 0;
  loadingMaskShow: boolean = false;

  subscription !: Subscription;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private notifyService: NotifyService
  ) { }


  ngOnInit(): void {
    // Create form
    this.profileUpdateForm = new FormGroup({
      currentpassword: new FormControl('', Validators.compose([
        Validators.required
      ])),
      newpassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
      ])),
      retypepassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8)
      ]))
    });
  }


  /**Clear form */
  clearForm() {
    this.profileUpdateForm.patchValue({
      currentpassword: "",
      newpassword: "",
      retypepassword: ""
    });
  }

  /**
   * Update profile
   */
  updatePassword() {
    if (this.profileUpdateForm.value.retypepassword == this.profileUpdateForm.value.newpassword) {
      if (this.profileUpdateForm.valid) {
        let _jsonData = {
          "password": this.profileUpdateForm.value.currentpassword,
          "newpassword": this.profileUpdateForm.value.newpassword,
        };
        console.log(_jsonData);
        //show mask
        this.loadingMaskShow = true;
        this.http.post(globalEnvironment.apiHostURL + "/user/changepassword", _jsonData).subscribe(
          (response: any) => {
            //hide mask
            this.loadingMaskShow = false;
            this.notifyService.showSuccess(response.message, "Password update");
            //clear
            this.clearForm();
            //navigate to domains
            this.router.navigate(['/domains']);
          },
          (error) => {
            //hide mask
            this.loadingMaskShow = false;
            //on auth invalid
            console.log("Return status = " + error);
            if (error.status == 401) {
              this.authService.removeLoginToken();
              this.router.navigate(['/login']);
            }
            //notify
            this.notifyService.showError(error.error.message, "Password update failed");
          });
      } else {
        // State as an error
        this.notifyService.showError("Please tr-try with correct information", "Password update failed");
      }
    } else {
      this.notifyService.showError("New password and re-typed password are mismatched", "Password update failed");
    }
  }
}
