<!--Top NavBar-->
<nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
    </ul>
    <h2>&#160;</h2>
</nav>
<!-- Top NavBar end -->
<!-- Primary NavBar -->
<aside class="main-sidebar sidebar-dark-primary elevation-4">
    <a href="index3.html" class="brand-link">
        <!--<img src="assets/img/logo-128x128.png" alt="Logo" class="brand-image img-circle elevation-3"
            style="opacity: .8"> -->
        <span class="brand-text font-weight-light">No Host File Needed</span>
    </a>
    <br />
    <div class="sidebar">
        <!--Search start-->
        <!--<div class="form-inline">
            <div class="input-group" data-widget="sidebar-search">
                <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
                <div class="input-group-append">
                    <button class="btn btn-sidebar">
                        <i class="fas fa-search fa-fw"></i>
                    </button>
                </div>
            </div>
        </div>-->
        <!--Search end-->
        <!--Menu start-->
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <!-- Domains -->
                <li class="nav-item">
                    <a routerLink="/domains" routerLinkActive="active" class="nav-link">
                        <i class="nav-icon fas fa-solid fa-globe"></i>
                        <p>
                            Domains
                        </p>
                    </a>
                </li>
                <!-- Domains end -->
                <!-- Users -->
                <li class="nav-item" #menuUsers *ngIf="adminUser">
                    <a href="#" class="nav-link">
                        <i class="nav-icon fas fa-solid fa-users"></i>
                        <p>
                            Users
                        </p>
                        <i class="right fas fa-angle-left"></i>
                    </a>
                    <ul class="nav nav-treeview" *ngIf="adminUser">
                        <li class="nav-item">
                            <a routerLink="/users" routerLinkActive="active" class="nav-link">
                                <i class="far fa-solid fa-address-book nav-icon"></i>
                                <p>List users</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/users/create" routerLinkActive="active" class="nav-link">
                                <i class="far fa-solid fa-user-plus nav-icon"></i>
                                <p>Create user</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <!-- Users end -->
                <!-- Profile -->
                <li class="nav-item">
                    <a routerLink="/profile" routerLinkActive="active" class="nav-link">
                        <i class="nav-icon fas fa-solid fa-gear"></i>
                        <p>
                            Profile
                        </p>
                    </a>
                </li>
                <!-- Profile end -->
            </ul>
        </nav>
        <!--Menu end-->
    </div>
</aside>
<!-- Primary NavBar end -->
<!--Content-->
<div class="content-wrapper">
    <div class="content">
        <div class="container-fluid h-100">
            <div class="row">
                <div class="col-lg-12">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Content end-->
<!-- Footer -->
<footer class="main-footer">
    <div class="float-right d-none d-sm-inline"></div>
    <strong>Copyright &copy; <a href="https://nohostfileneeded.com">nohostfileneeded.com</a>.</strong> All rights reserved.
</footer>
<!-- Footer end -->