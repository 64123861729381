import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { NotifyService } from '../notify.service';
import { Subscription } from 'rxjs';
import { globalEnvironment } from '../app.module';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrl: './userlist.component.scss'
})
export class UserlistComponent implements OnInit {
  errorMessage: string | null | undefined = null;
  reTryCount: number = 0;
  loadingMaskShow: boolean = false;

  usersList = [{ userid: 0, username: "", admin: true, enabled: true }];

  subscription !: Subscription;


  modalUserDeletionPromptDisplayStyle = "none";
  modalUserDeletionPromptUserID = 0;
  modalUserDeletionPromptUserName = "";

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private notifyService: NotifyService
  ) { }


  /**
   * Init by loading users
   */
  ngOnInit(): void {
    this.refreshUsers();
  }


  /**
   * Refresh users
   */
  refreshUsers() {
    this.http.get(globalEnvironment.apiHostURL + "/user?" + Math.random()).subscribe(
      (response: any) => {
        this.usersList = response.users;
      },
      (error) => {
        //on auth invalid
        console.log("Return status = " + error);
        if (error.status == 401) {
          this.authService.removeLoginToken();
          this.router.navigate(['/login']);
        }
      });
  }


  /**
 * Show Deletion prompt modal
 */
  showDeletionPromptModal(userId: number, userName: string): void {
    this.modalUserDeletionPromptUserID = userId;
    this.modalUserDeletionPromptUserName = userName;
    this.modalUserDeletionPromptDisplayStyle = "block";
  }

  /**
  * Close Deletion promtp modal
  */
  closeDeletionPromptModal(): void {
    this.modalUserDeletionPromptDisplayStyle = "none";
    this.modalUserDeletionPromptUserID = 0;
    this.modalUserDeletionPromptUserName = "";
  }



  /**Delete user */
  deleteUser(userid: number) {
    this.http.delete(globalEnvironment.apiHostURL + "/user/" + userid).subscribe(
      (response: any) => {
        //hide mask
        this.loadingMaskShow = false;
        this.notifyService.showSuccess(response.message, "User deleted");
        this.refreshUsers();
        this.closeDeletionPromptModal();
      },
      (error) => {
        //hide mask
        this.loadingMaskShow = false;
        //on auth invalid
        console.log("Return status = " + error);
        if (error.status == 401) {
          this.authService.removeLoginToken();
          this.router.navigate(['/login']);
        }
        //notify
        this.notifyService.showError(error.error.message, "User deletion failed");
        this.refreshUsers();
        this.closeDeletionPromptModal();
      });
  }



}
