import { Component, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { globalEnvironment } from '../app.module';
import { AuthenticationService } from '../authentication.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  errorMessage: string | null | undefined = null;
  loginForm!: FormGroup;
  reTryCount : number = 0;
  loadingMaskShow: boolean = false;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.compose([
        Validators.required]
      )),
      password: new FormControl('', Validators.compose([
        Validators.required
      ])
      )
    });
  }

  ngAfterViewInit(): void {
    try {
      if (this.authService.getLoginToken() != undefined || this.authService.getLoginToken() != null) {
        //redirect on token present
        this.router.navigate(['/domains']);
      }
    } catch (e) { }
  }

  submitLogin(): void {
    if (this.loginForm.valid) {
      //show mask
      this.loadingMaskShow = true;
      // Process login
      let loginStat:boolean = this.authService.login(this.loginForm.value.username, this.loginForm.value.password);
      if (loginStat) {
        //success
        this.router.navigate(['/domains']);
      } else {
        //if no errors als treat as success
        if(this.authService.getLoginToken()!= null || this.authService.getLoginToken()!= undefined){
          this.router.navigate(['/domains']);
        }else{
          //re try 3 times and show as error
          if (this.reTryCount < 3){
            this.reTryCount++;
            setTimeout( ()=>{this.submitLogin()},1000);
          }else{
            //clear retry count
            this.reTryCount = 0;
            // State as an error
            this.errorMessage = this.authService.getLoginError();
            //hide mask
            this.loadingMaskShow = false;
          }
        }
      }
    } else {
      // State as an error
      this.errorMessage = "Please tr-try with valid credentials";
    }
  }

}
