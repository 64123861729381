import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { NotifyService } from '../notify.service';
import { globalEnvironment } from '../app.module';
import { Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit {
  errorMessage: string | null | undefined = null;
  reTryCount: number = 0;
  loadingMaskShow: boolean = false;


  subscription !: Subscription;


  username = "";
  adminUser = false;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private router: Router,
    private notifyService: NotifyService
  ) { }

  /**
   * Fetch username and level as admin or not
   */
  ngOnInit(): void {
    this.http.get(globalEnvironment.apiHostURL + "/user/session/validate?" + Math.random()).subscribe(
      (response: any) => {
        this.username = response.username;
        this.adminUser = response.admin;
      },
      (error) => {
        //on auth invalid
        console.log("Return status = " + error);
        if (error.status == 401) {
          this.authService.removeLoginToken();
          this.router.navigate(['/login']);
        }
      });
  }


}
