<!-- List -->
<div class="row">
    <div class="container-fluid">
        <div class="card  card-outline card-dark">
            <!--Card body-->
            <div class="card-body">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th style="width: 10px">#</th>
                            <th>Username</th>
                            <th>User type</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of usersList;index as i">
                            <td>{{i+1}}.</td>
                            <td>{{user.username}}</td>
                            <td>
                                <span *ngIf="user.admin">Admin user</span>
                                <span *ngIf="!user.admin">Regular user</span>
                            </td>
                            <td>
                                <button type="button" class="btn btn-light btn-outline-danger" 
                                    (click)="showDeletionPromptModal(user.userid,user.username)">
                                    <i class="far fa-solid fa-trash-can"></i>&#160; Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<!-- List end -->



<!--User deletion prompt modal-->
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':modalUserDeletionPromptDisplayStyle}"
    id="modalUserDeletion">
    <div class="modal-dialog">
        <div class="modal-content bg-warning">
            <div class="modal-header">
                <h4 class="modal-title">Are you sure ?</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #modalUserDeletionCloseBtn
                    (click)="closeDeletionPromptModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    Are you sure you want to delete user
                    <strong>{{modalUserDeletionPromptUserName}}</strong> ?
                </p>
            </div>
            <div class="modal-footer justify-content-right">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteUser(modalUserDeletionPromptUserID)"><i class="far fa-solid fa-trash-can"></i>&#160;Delete</button>
                <button type="button" class="btn btn-info" data-dismiss="modal" (click)="closeDeletionPromptModal()"><i class="far fa-solid fa-circle-xmark"></i>&#160;Close</button>
            </div>
        </div>
    </div>
</div>
<!--User deletion prompt modal end-->