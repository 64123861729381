import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { globalEnvironment } from './app.module';
import { AuthenticationService } from './authentication.service';


export const customHttpInterceptor: HttpInterceptorFn = (req, next) => {
  let authToken: string | null | undefined = null;
  //read from local storage if present
  if (document.defaultView?.localStorage != undefined) {
    const localStorage = document.defaultView?.localStorage;
    authToken = localStorage.getItem(globalEnvironment.authTokenKey);
  }
  //build headers
  const headers = new HttpHeaders({ 
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Access-Control-Allow-Origin, accept, content-type",
    "Access-Control-Allow-Methods": "GET, OPTIONS",
    "Content-Type": "application/json; charset=utf-8",
    "Authorization": (authToken!=null) ? authToken : "",
    /*'cache-control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
    'pragma': 'no-cache',
    'expires': '0'*/
  });


  const modifiedReq = req.clone({
    headers: headers,
  });

  return next(modifiedReq);
};
